<template>
    <div class="card">
        <template>
            <img
                v-if="fotocode == 'Wel zien'"
                :src="pbfoto"
                alt="Profielfoto niet gevonden"
                class="card__foto"
                load="lazy"
                @click="$router.push(`/voorstellen/${voorstelnummer}`)"
            />
            <div
                class="card__foto"
                v-else
                style="display: flex; justify-content: center; align-items: center"
                @click="$router.push(`/voorstellen/${voorstelnummer}`)"
            >
                <h4 class="oleo" style="display: block">
                    Foto {{ [null, 'Niet gekozen'].includes(fotocode) ? 'nog ' : '' }}niet zichtbaar
                </h4>
            </div>
        </template>
        <div class="card__content">
            <h4 class="oleo card__naam">{{ voornaam }}</h4>
            <p class="card__datum" :style="{ 'font-weight': openstaand ? 'bold' : 'normal' }">
                Verzonden: {{ toonVoorstelDatum(datum) }}
            </p>
            <button
                class="btn btn-rounded card__btn"
                @click="$router.push(`/voorstellen/${voorstelnummer}`)"
                :style="{ 'font-weight': openstaand ? 'bold' : 'normal' }"
            >
                Bekijk het voorstel
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { toonLangeDatum, toonKorteDatum, toonVoorstelDatum } from '../functions/toonDatum';
    import AvatarVoorstelMan from '../assets/avatar-voorstel-man.jpeg';
    import AvatarVoorstelVrouw from '../assets/avatar-voorstel-vrouw.jpeg';

    export default {
        data() {
            return {};
        },
        props: ['voorstelnummer', 'voornaam', 'datum', 'openstaand', 'fotocode', 'persoonId'],
        computed: {
            ...mapGetters(['kandidaat']),
            pbfoto() {
                const persoon = this.kandidaat(this.persoonId);
                const fileId = persoon?.Profielfoto?.[0].File_Id || null;
                if (fileId) {
                    return `${this.$baseUrl}/foto/${this.persoonId}/${fileId}`;
                } else {
                    return persoon.Geslacht === 'M' ? AvatarVoorstelMan : AvatarVoorstelVrouw;
                }
            },
        },
        methods: {
            toonLangeDatum,
            toonKorteDatum,
            toonVoorstelDatum,
        },
    };
</script>
